@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';
@import '~@angular/cdk/overlay-prebuilt.css';

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Italic.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('/assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('/assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
$mobil-width: 1180px;

* {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  line-height: 1.6;

  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

.content-wrapper {
  width: 92%;
  max-width: 1780px;
  margin: auto;
}

h1 {
  font-size: 1.5rem;
}

// <br>s mess up text of lower line-height, otherwise
br {
  line-height: 0;
}

.bold-text {
  font-size: inherit;
  font-weight: bold;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  * {
    font-size: 18px;
  }
}

.glide__arrow {
  box-shadow: none;
  text-shadow: none;
}

#user-form {
  input[type='text'],
  select,
  .p-multiselect {
    height: 1.33rem;
  }
}
